@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900&display=swap&subset=latin-ext");

/*
    Montserrat:
	Thin:      		          100
	Extra-Light:      		  200
	Light:      		      300
    Regular:       		      400
    Medium:       		      500
    Semi-Bold:      		  600
    Bold:          			  700
    Extra-Bold:          	  800
    Black:          	      900
*/

* {
  margin: 0;
  padding: 0;
}
html {
  overflow-y: scroll;
}

/* General styles */
body {
  float: left;
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin: 0;
  padding: 0;
  padding-bottom: 50px;
}
body,
p {
  color: #878787;
  font-family: "Montserrat", arial, tahoma, verdana;
  font-size: 14px;
  line-height: 24px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", arial, tahoma, verdana;
}
img {
  border: 0px;
}

*::before,
*::after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: #170900;
  font-size: 14px;
  font-weight: 700;
}
a,
a span,
a strong,
label {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
a:hover {
  color: #000;
}
a:active {
  outline: none;
}
a:focus,
object {
  outline: none;
}
input,
input:focus,
input:active {
  outline: none;
}

/* Main styles */
.skip {
  display: none;
}
.relative {
  position: relative !important;
}
.center {
  width: 90%;
  margin: 0px auto;
}
.centerSection {
  position: relative;
  display: flex;
  padding-left: 100px;
  padding-right: 100px;
  flex-wrap: wrap;
  margin: 0 auto;
}
.mTB30px {
  margin-top: 30px;
  margin-bottom: 30px;
}
.h100perc {
  height: 100% !important;
}

#top {
  float: left;
  width: 100%;
  background-color: #fff;
  z-index: 9001;
  box-sizing: border-box;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 10px;
  position: fixed;
}
#top {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
h1 {
  display: inline-block;
  padding: 0px;
  margin: 0px;
}
#logo {
  display: inline-block;
  width: 127px;
  height: 37px;
  background: transparent url(./images/logo.png) no-repeat center center;
  text-indent: -9999px;
}
#logo:hover {
  opacity: 0.8;
}

#nav {
  list-style: none;
  padding: 0;
  margin: 0;
}
#nav li {
  display: inline-block;
}
#nav li a {
  display: inline-block;
  color: #000;
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
  padding-left: 60px;
}
#nav li a:hover {
  color: #d3242b;
}

/* nav hamburger styles -------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */

.button_container {
  display: none;
  position: relative;
  top: 0px;
  right: 0px;
  float: right;
  height: 27px;
  width: 35px;
  cursor: pointer; /*z-index: 901;*/
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
#top.noTransparent .button_container {
  position: relative;
  top: 10px;
  right: 30px;
  float: right;
  height: 27px;
  width: 35px;
  cursor: pointer; /*z-index: 901;*/
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.button_container:hover {
  opacity: 0.7;
}
.button_container.active .top {
  -webkit-transform: translateY(11px) translateX(0) rotate(45deg);
  transform: translateY(11px) translateX(0) rotate(45deg);
  background: #d3242b;
}
.button_container.active .middle {
  opacity: 0;
  background: #d3242b;
}
.button_container.active .bottom {
  -webkit-transform: translateY(-11px) translateX(0) rotate(-45deg);
  transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: #d3242b;
}
.button_container span {
  background: #000;
  border: none;
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  cursor: pointer;
}
.button_container span:nth-of-type(2) {
  top: 11px; /* width: 60%; */
}
.button_container span:nth-of-type(3) {
  top: 22px; /* width: 90%; */
}

.overlay {
  z-index: 9000;
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
}
.overlay.open {
  opacity: 0.98;
  visibility: visible;
  height: 100%; /*max-height:900px;*/
  z-index: 9000;
}

.overlay.open li {
  -webkit-animation: fadeInRight 0.5s ease forwards;
  animation: fadeInRight 0.5s ease forwards;
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}
.overlay.open li:nth-of-type(2) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.overlay.open li:nth-of-type(3) {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}
.overlay.open li:nth-of-type(4) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.overlay.open li:nth-of-type(5) {
  -webkit-animation-delay: 0.55s;
  animation-delay: 0.55s;
}

.overlay.open ul.subNav li {
  -webkit-animation: fadeInRight 0.5s ease forwards;
  animation: fadeInRight 0.5s ease forwards;
  -webkit-animation-delay: 0.65s;
  animation-delay: 0.65s;
}

.overlay nav {
  /*margin: 210px 0 0 0;*/
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  display: flex;
  vertical-align: middle;
  align-content: center;
  align-items: center;
}
.overlay ul {
  list-style: none;
  padding: 0;
  display: inline-block;
  margin: 0px auto;
}
.overlay ul li {
  display: block;
  position: relative;
  opacity: 0;
}
.overlay ul li a {
  display: block;
  position: relative;
  color: #000;
  text-decoration: none;
  font-size: 50px;
  line-height: 80px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
}
.overlay ul li a:hover,
.overlay ul li.current_page_item a {
  color: #d3242b;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

#header {
  float: left;
  width: 100%;
  position: relative;
}
#headerDesc {
  position: absolute;
  left: 100px;
  z-index: 901;
  width: 750px;
  top: 50%;
  transform: translateY(-40%);
}
#headerDesc h2 {
  display: inline-block;
  color: #fff;
  font-size: 46px;
  line-height: 56px;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 20px;
}
#headerDesc p {
  display: inline-block;
  color: #fff;
  font-size: 22px;
  line-height: 32px;
  font-weight: 300;
  width: 80%;
  margin-bottom: 20px;
}
#headerDesc a {
  display: inline-block;
  color: #fff;
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  padding: 20px 50px;
}

#blackBtn {
  background-color: #000;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  border: 2px solid #000;
}
#blackBtn {
  -webkit-box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.4);
}
#blackBtn:hover {
  background-color: transparent;
  opacity: 1;
  border: 2px solid #fff;
}

#headerBg {
  position: relative;
  width: 100%;
  height: auto;
}
#headerBg img {
  width: 100%;
}
.lSGallery {
  position: absolute;
  bottom: 50px;
  right: 100px;
  z-index: 4000;
}
.lSGallery li.active {
  opacity: 0.4;
}

#program {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-top: 90px;
  display: block;
}
#program h3 {
  display: inline-block;
  width: 100%;
  color: #170900;
  font-size: 42px;
  line-height: 52px;
  font-weight: 600;
  text-align: center;
  margin: 0 0 10px 0;
}
#program .subtitle {
  display: inline-block;
  width: 100%;
  color: #170900;
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
  text-align: center;
}
#program .diagram {
  text-align: center;
  margin-top: 30px;
}
#program .diagram img {
  width: 80%;
}

/* Program navigation ------------------------ */
.navbar {
  display: inline-block;
  width: 30%;
  padding: 0px;
  margin: 80px 0 0 0;
  list-style: none;
  border-left: 3px solid #ccc;
}
.navbar li {
  display: block;
  width: 100%;
  margin: 5px 0 15px -3px;
  padding: 10px 0 10px 50px;
  border-left: 3px solid #000;
  position: relative;
}
.navbar li.toSelect,
navbar li.selected {
  border: none;
}
.navbar li a {
  display: block;
  width: 100%;
  color: #000;
  font-size: 20px;
  font-weight: 600;
}
.navbar li a span {
  display: none;
  width: 80%;
  color: #000;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  margin-top: 6px;
}
.navbar li.active a span {
  display: block;
}
.navbar li.toSelect a {
  opacity: 0.3;
  cursor: not-allowed;
}
.navbar li.selected a {
  opacity: 0.3;
}
.navbar li.selected:hover a {
  opacity: 1;
}

#tabNav1::before {
  content: "1";
  position: absolute;
  left: 30px;
  color: #000;
  font-size: 20px;
  font-weight: 600;
}
#tabNav2::before {
  content: "2";
  position: absolute;
  left: 30px;
  color: #000;
  font-size: 20px;
  font-weight: 600;
}
#tabNav3::before {
  content: "3";
  position: absolute;
  left: 30px;
  color: #000;
  font-size: 20px;
  font-weight: 600;
}
#tabNav4::before {
  content: "4";
  position: absolute;
  left: 30px;
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

#tabNav1.toSelect::before,
#tabNav2.toSelect::before,
#tabNav3.toSelect::before,
#tabNav4.toSelect::before {
  opacity: 0.3;
}
#tabNav1.selected::before,
#tabNav2.selected::before,
#tabNav3.selected::before,
#tabNav4.selected::before {
  opacity: 1;
  content: url(./images/checked.png);
  left: 25px;
}

/* Program form ------------------------ */
#stepsForm {
  margin-top: 90px;
}
.program-wizard {
  display: block;
  width: 65%;
  text-align: center;
  float: right;
}
#stepsForm label {
  width: 36%;
  max-width: 400px;
  padding: 40px 30px;
  display: inline-block;
  border: 1px solid #c8c8c8;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  margin: 10px;
  vertical-align: top;
}
#stepsForm label .labelTxt {
  color: #170900;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}
#stepsForm label.selectedLabel .labelTxt {
  color: #fff;
}

#stepsForm label.selectedLabel .iconGruszka {
  content: url(./images/gruszkajasne.PNG);
}
#stepsForm label.selectedLabel .iconBurak {
  content: url(./images/burakjasne.png);
}
#stepsForm label.selectedLabel .iconCebula {
  content: url(./images/cebulajasne.png);
  margin-left: 5px;
}
#stepsForm label.selectedLabel .iconKapusta {
  content: url(./images/kapustajasne.png);
  margin-left: 5px;
}
#stepsForm label.selectedLabel .iconOgorek {
  content: url(./images/ogorekjasne.png);
}
#stepsForm label.selectedLabel .iconPomidor {
  content: url(./images/pomidorjasne.png);
  margin-left: 5px;
}
#stepsForm label.selectedLabel .iconTruskawka {
  content: url(./images/truskawkajasne.png);
}
#stepsForm label.selectedLabel .iconMarchew {
  content: url(./images/marchewjasne.PNG);
  margin-left: 5px;
}
#stepsForm label.selectedLabel .iconKukurydza {
  content: url(./images/kukurydajasne.PNG);
}
#stepsForm label.selectedLabel .iconJeczmien {
  content: url(./images/jeczmnienjasne.png);
}
#stepsForm label.selectedLabel .iconPszenica {
  content: url(./images/pszenicajasne.png);
}
#stepsForm label.selectedLabel .iconRzepak {
  content: url(./images/rzepakjasne.png);
}
#stepsForm label.selectedLabel .iconZiemniaki {
  content: url(./images/ziemniakijasne.png);
  margin-left: 5px;
}
#stepsForm label.selectedLabel .iconInne {
  content: url(./images/innejasne.PNG);
  margin-left: 5px;
}
#stepsForm label.selectedLabel .iconMalina {
  content: url(./images/malinajasne.png);
}
#stepsForm label.selectedLabel .iconPorzeczka {
  content: url(./images/porzeczkajasne.png);
  margin-left: 5px;
}
#stepsForm label.selectedLabel .iconSliwa {
  content: url(./images/sliwkajasne.png);
}
#stepsForm label.selectedLabel .iconJablon {
  content: url(./images/jablkojasne.png);
}
#stepsForm label.selectedLabel .iconKalafior {
  content: url(./images/kalafiorjasne.png);
}

#stepsForm label .iconGruszka {
  content: url(./images/gruszkaciemne.PNG);
}
#stepsForm label .iconBurak {
  content: url(./images/burakciemne.png);
}
#stepsForm label .iconCebula {
  content: url(./images/cebulaciemne.png);
  margin-left: 5px;
}
#stepsForm label .iconKapusta {
  content: url(./images/kapustaciemne.PNG);
  margin-left: 5px;
}
#stepsForm label .iconOgorek {
  content: url(./images/ogorekciemne.png);
}
#stepsForm label .iconPomidor {
  content: url(./images/pomidorciemne.png);
  margin-left: 5px;
}
#stepsForm label .iconTruskawka {
  content: url(./images/truskawkaciemne.png);
}
#stepsForm label .iconMarchew {
  content: url(./images/marchewciemne.PNG);
  margin-left: 5px;
}
#stepsForm label .iconKukurydza {
  content: url(./images/kukurydzaciemne.PNG);
}
#stepsForm label .iconJeczmien {
  content: url(./images/jeczmnienciemne.png);
}
#stepsForm label .iconPszenica {
  content: url(./images/pszenicaciemne.png);
}
#stepsForm label .iconRzepak {
  content: url(./images/rzepakciemne.png);
}
#stepsForm label .iconZiemniaki {
  content: url(./images/ziemniakiciemne.png);
  margin-left: 5px;
}
#stepsForm label .iconInne {
  content: url(./images/inneciemne.PNG);
  margin-left: 5px;
}
#stepsForm label .iconMalina {
  content: url(./images/malinaciemne.png);
}
#stepsForm label .iconPorzeczka {
  content: url(./images/porzeczkaciemne.png);
  margin-left: 5px;
}
#stepsForm label .iconSliwa {
  content: url(./images/sliwkaciemne.png);
}
#stepsForm label .iconJablon {
  content: url(./images/jablkociemne.png);
}
#stepsForm label .iconKalafior {
  content: url(./images/kalafiorciemne.png);
}

#stepsForm label.selectedLabel {
  color: #fff;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(62, 60, 60, 1) 100%
  );
  -webkit-box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.2);
}
#stepsForm label img {
  vertical-align: middle;
  max-width: 64px;
}
#stepsForm .iconContainer {
  padding: 10px 30px;
}

/* Styles for hiding the native checkbox */
input[type="checkbox"].check-custom {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* Styles for the basic appearance of the custom checkbox */
input[type="checkbox"].check-custom ~ .check-toggle {
  width: 1rem;
  height: 1rem;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #431b02;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: -3px;
}

/* Styles for the hover state appearance of the custom checkbox */
input[type="checkbox"].check-custom:hover ~ .check-toggle {
  border-color: #4a4a4a;
  background-color: #fff;
}

/* Styles for the focus state appearance of the custom checkbox */
input[type="checkbox"].check-custom:focus ~ .check-toggle {
  border-color: #b0d5ff;
  box-shadow: 0 0 0 2px rgba(23, 133, 255, 0.25);
}

/* Styles for the checked state appearance of the custom checkbox */
input[type="checkbox"].check-custom:checked ~ .check-toggle {
  border-color: #fff;
  background: #fff
    url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxNiAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kOyI+PHBhdGggZD0iTTEzLjMzMyw0bC03LjMzMyw3LjMzM2wtMy4zMzMsLTMuMzMzIiBzdHlsZT0iZmlsbDpub25lO2ZpbGwtcnVsZTpub256ZXJvO3N0cm9rZTojMDAwO3N0cm9rZS13aWR0aDoycHg7Ii8+PC9zdmc+)
    center no-repeat;
  background-size: 75%;
}

.btnsHolder {
  display: inline-block;
  margin-top: 40px;
  width: 100%;
}
.btnBack {
  margin: 0 10px;
  display: inline-block;
  color: #000;
  border: 1px solid #000;
  padding: 20px 50px;
  font-size: 16px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
}
.btnBack:hover {
  opacity: 0.6;
}
.btnNext {
  margin: 0 10px;
  display: inline-block;
  color: #fff;
  border: 1px solid #000;
  padding: 20px 50px;
  font-size: 16px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
}
.btnNext {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(62, 60, 60, 1) 100%
  );
  -webkit-box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.2);
}
.btnNext:hover {
  opacity: 0.7;
  color: #fff;
}
.inactive {
  opacity: 0.3;
}
.inactive:hover {
  opacity: 0.3;
  cursor: not-allowed;
}

.inputsHolder {
  width: 100%;
}
.inputsHolder .simpleInput,
.inputsHolder .required {
  display: inline-block;
  width: 38%;
  max-width: 400px;
  margin: 10px;
  position: relative;
}
.inputsHolder input {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  border: 1px solid #c8c8c8;
  color: #a29d99;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.inputsHolder .required:after {
  content: " *";
  color: #000;
  position: absolute;
  right: 20px;
  font-size: 20px;
  top: 50%;
  line-height: 10px;
}

#korzysci {
  float: left;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
#korzysciDesc {
  position: absolute;
  left: 100px;
  width: 600px;
  top: 50%;
  transform: translateY(-50%);
}
#korzysciDesc h3 {
  display: inline-block;
  width: 100%;
  color: #170900;
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}
#korzysciDesc p {
  display: inline-block;
  color: #170900;
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
  width: 70%;
}
#korzysciDesc a {
  display: inline-block;
  color: #170900;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  width: 70%;
  margin-top: 30px;
}
#korzysciDesc a:hover {
  color: #d3242b;
}

#korzysci img {
  width: 100%;
}

#application {
  float: left;
  width: 100%;
  padding: 70px 0;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
#application img.phonesImg {
  display: inline-block;
  width: 49%;
}
#appDesc {
  display: inline-block;
  width: 49%;
}
#appDesc h3 {
  display: inline-block;
  width: 90%;
  color: #170900;
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}
#appDesc p {
  display: inline-block;
  width: 70%;
  color: #170900;
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
}
.buttonsHolder {
  display: inline-block;
  width: 100%;
  margin-top: 30px;
}
.buttonsHolder a {
  margin-right: 10px;
}
.buttonsHolder a:hover {
  opacity: 0.7;
}

#details {
  width: 100%;
  padding: 70px 40px 40px 0;
}
#details .textcontainer {
  float: left;
  width: 50%;
}
#details .textcontainer p {
  padding-right: 40px;
  padding-top: 10px;
  font-size: 1.3em;
}
#details .imagecontainer {
  float: left;
  width: 50%;
  text-align: center;
}
#details .imagecontainer img {
  width: 75%;
}

#value {
  width: 100%;
  padding: 70px 40px 40px 0;
}
#value .header {
  width: 100%;
  padding-left: 5px;
}
#value .header img {
  float: left;
  width: 33%;
}
#value .content {
  padding-top: 10px;
  float: left;
}
#value .content p {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  font-size: 1.3em;
}
#value .content ul {
  padding-left: 50px;
}

#contact {
  width: 100%;
  padding: 70px 40px 40px 0;
}
#contact p {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  font-size: 1.3em;
}

#price {
  width: 100%;
  padding: 70px 40px 40px 0;
}
#price p {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  font-size: 1.3em;
}
#contact img {
  padding-left: 10px;
  width: 600px;
}

#books {
  float: left;
  width: 100%;
  background-color: #f5f5f5;
  padding: 70px 0;
  display: flex;
  align-items: center;
  position: relative;
}
#booksIntro {
  display: inline-block;
  margin-left: 100px;
  width: 420px;
}
#booksIntro h3 {
  display: inline-block;
  width: 100%;
  color: #170900;
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}
#booksIntro p {
  display: inline-block;
  width: 80%;
  color: #170900;
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
}
#booksIntro a {
  display: inline-block;
  width: 80%;
  color: #170900;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin-top: 30px;
}
#booksIntro a:hover {
  color: #d3242b;
}

#booksSlideHolder {
  display: block;
  width: 70%;
  overflow: hidden;
  float: right;
  padding: 30px 0 30px 100px;
  box-sizing: border-box;
}
#books .lightSlider,
#books .lSSlideOuter,
#books .lSSlideOuter,
.lSSlideWrapper {
  overflow: visible !important;
}
/*#books .lSSlideOuter {padding: 40px;}*/

li.lslide a img {
  margin: 0 30px;
}
li.lslide a img {
  -webkit-box-shadow: 0px 20px 24px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 20px 24px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 20px 24px 0px rgba(0, 0, 0, 0.15);
}
/*.lSSlideOuter {padding: 0 0 0 100px;}*/
/*li.lslide:first-child a img {padding-left: 100px;}*/
li.lslide:last-child a img {
  margin-right: 100px;
}

#books .lSSlideOuter .lSPager.lSpg {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
}
#books .lSSlideOuter .lSPager.lSpg > li a {
  background-color: #bbb;
  width: 10px;
  height: 10px;
  border: 4px solid #f5f5f5;
}
#books .lSSlideOuter .lSPager.lSpg > li.active a {
  background-color: #f5f5f5;
  border: 4px solid #666;
}

#footer {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-top: 40px;
}
#footer ul {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
#footer ul li {
  display: inline-block;
}
#footer ul li a {
  display: inline-block;
  color: #000;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  opacity: 0.6;
  margin: 0 30px;
}
#footer ul li a:hover {
  opacity: 1;
}

.searchBar {
  width: 25%;
}

.consent {
  clear: both;
}
.consent-column-sm {
  float: left;
  width: 5%;
}

.consent-column-l {
  float: left;
  width: 95%;

  padding-top: 5px;
  text-align: left;
}

/* --------------------------- MEDIA QUERIES ----------------------------  */

@media all and (max-width: 1200px) {
  #headerBg img {
    height: auto;
    display: block;
  }
  #headerBg .lSSlideOuter {
    display: none;
  }
  #headerDesc {
    width: 100%;
    left: 0px;
    top: 25%;
    text-align: center;
  }
  #headerDesc h2 {
    color: #000;
  }
  #headerDesc p {
    color: #000;
  }
  #headerDesc a {
    color: #000;
  }
  #headerDesc #blackBtn {
    color: #fff;
  }
  #headerDesc #blackBtn:hover {
    color: #000;
  }
  #stepsForm1,
  #stepsForm2,
  #stepsForm3,
  #stepsForm4 {
    width: 100%;
    padding-top: 30px;
  }
  .navbar {
    text-align: center;
    width: 100%;
    border: none;
    margin-top: 40px;
  }
  .navbar li {
    padding-left: 0px;
    padding-right: 0px;
    border: none;
  }
  .navbar li a span {
    width: 100%;
  }
  #tabNav1::before,
  #tabNav2::before,
  #tabNav3::before,
  #tabNav4::before {
    position: static;
  }
  #korzysci {
    text-align: center;
  }
  #korzysciDesc {
    position: static;
    width: 100%;
    transform: none;
    padding-top: 50px;
    text-align: center;
  }
  #korzysci img {
    content: url(./images/korzysci_mobile.jpg);
    width: 80%;
    display: inline-block;
  }
  #application {
    display: inline-block;
    text-align: center;
  }
  #application img.phonesImg {
    width: 70%;
    margin-top: 50px;
  }
  #books {
    text-align: center;
    display: inline-block;
    overflow: hidden;
  }
  #booksIntro {
    margin: 0;
    width: 60%;
  }
  #booksSlideHolder {
    float: none;
    width: 100%;
    padding: 0px;
    overflow: visible;
  }
  #lightSlider {
    width: 100%;
    margin-top: 50px;
  }
  .lSPager {
    display: none;
  }
  .lSSlideOuter .lSGallery li {
    width: 49%;
    display: inline-block;
  }
  .program-wizard {
    width: 100%;
  }
  #stepsForm label {
    width: 60%;
    max-width: 60%;
  }
  .searchBar {
    width: 100%;
  }
  .virtualList {
    margin-left: 0px;
    margin-top: 30px;
    width: 100%;
  }
}

@media all and (max-width: 1024px) {
  #headerDesc {
    top: 50%;
  }
  #headerBg {
    opacity: 0.2;
  }
  #appDesc {
    width: 80%;
  }
  #headerDesc #blackBtn {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(62, 60, 60, 1) 100%
    );
  }
  #headerDesc #blackBtn:hover {
    background-color: #000;
    border: 2px solid #000;
    color: #fff;
  }
  #program {
    text-align: center;
  }
  #program h3,
  #program p {
    width: 80%;
  }
  #top {
    text-align: center;
  }
  .button_container {
    display: block;
    top: 5px;
  }
}

@media all and (max-width: 800px) {
  #headerDesc h2 {
    font-size: 36px;
    line-height: 40px;
  }
  #headerDesc p {
    font-size: 18px;
    line-height: 26px;
  }
  #headerDesc a {
    font-size: 18px;
    line-height: 26px;
  }
  #program h3,
  #korzysciDesc h3,
  #appDesc h3,
  #booksIntro h3 {
    font-size: 32px;
    line-height: 38px;
  }
  #program p,
  #korzysciDesc p,
  #korzysciDesc a,
  #booksIntro a,
  #appDesc p,
  #booksIntro p {
    font-size: 18px;
    line-height: 26px;
  }
  form label {
    width: 70%;
  }
  #booksIntro {
    width: 80%;
  }
  .inputsHolder .simpleInput,
  .inputsHolder .required {
    width: 80%;
    max-width: 500px;
  }
  .btnNext,
  .btnBack {
    padding: 15px 40px;
  }
  .overlay ul li a {
    font-size: 30px;
  }
  #footer ul li {
    width: 80%;
  }
  #footer ul li a {
    line-height: 40px;
  }
  .program-wizard {
    width: 100%;
  }
  #stepsForm label {
    width: 60%;
    max-width: 60%;
  }
  .searchBar {
    width: 100%;
  }
  .virtualList {
    margin-left: 0px !important;
    margin-top: 30px !important;
    width: 100% !important;
  }
  #program .diagram {
    display: none;
  }
  .consent-column-sm {
    width: 25px;
  }
}

@media all and (max-width: 700px) {
  .buttonsHolder {
    width: 70%;
  }
  .centerSection {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media all and (max-width: 600px) {
  .inputsHolder .simpleInput,
  .inputsHolder .required {
    width: 100%;
  }
  .btnNext,
  .btnBack {
    width: 90%;
    box-sizing: border-box;
  }
  .btnBack {
    margin-bottom: 10px;
  }
  #headerDesc #blackBtn {
    width: 100%;
    box-sizing: border-box;
  }
  #headerDesc h2 {
    font-size: 30px;
    line-height: 32px;
  }
  #headerBg img {
    width: 130%;
  }
  #header {
    overflow: hidden;
  }
  #program h3,
  #korzysciDesc h3,
  #appDesc h3,
  #booksIntro h3 {
    font-size: 28px;
    line-height: 34px;
  }
  .buttonsHolder {
    width: 70%;
  }
  #appDesc .buttonsHolder {
    width: 100%;
  }
}

@media all and (max-width: 500px) {
  .overlay ul li a {
    line-height: 60px;
  }
  #application img.phonesImg {
    width: 100%;
  }
}

@media all and (max-width: 450px) {
  #program h3,
  #korzysciDesc h3,
  #appDesc h3,
  #booksIntro h3 {
    width: 80%;
  }
  #headerBg img {
    width: 150%;
  }
  form label img {
    display: none;
  }
  #stepsForm2 label {
    padding: 40px 30px;
  }
}

@media all and (max-width: 400px) {
  #headerBg img {
    width: 180%;
  }
  #headerDesc a {
    padding: 20px 30px;
  }
  .overlay ul li {
    margin: 20px 0;
  }
  .overlay ul li a {
    line-height: 30px;
  }
  .centerSection {
    padding-left: 30px;
    padding-right: 30px;
  }
  .inputsHolder input {
    width: 90%;
  }
  .inputsHolder .required:after {
    right: 30px;
  }
  #appDesc .buttonsHolder a {
    margin-right: 0px;
    margin-bottom: 10px;
    width: 100%;
    display: inline-block;
  }
  #program h3,
  #korzysciDesc h3,
  #appDesc h3,
  #booksIntro h3 {
    font-size: 24px;
    line-height: 28px;
  }
}

@media all and (max-width: 360px) {
  #headerBg img {
    width: 220%;
  }
}

.hidden { display:none; }
